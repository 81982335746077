<template>
  <div>
    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <div>
      <v-data-table
        :headers="headers"
        :items="rwpasangans"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        :loading="ldata"
        hide-default-footer
        style="text-overflow: ellipsis; white-space: nowrap"
      >
        <template v-slot:top>
          <rwpasanganModalEdit />
          <rwpasanganModalHapus />
        </template>
        <template v-slot:[`item.kolom1`]="{ item }">
          {{ item.kelPasangan.nama }} <br />
          {{ item.kelPasangan.tempatLahir }}, {{ item.kelPasangan.tglLahir }}
        </template>
        <template v-slot:[`item.kolom2`]="{ item }">
          {{ item.kelPasangan.dataPernikahan.aktaMenikah }} <br />
          {{ item.kelPasangan.dataPernikahan.tgglMenikah }}
        </template>
        <template v-slot:[`item.kolom3`]="{ item }">
          <div v-if="item.kelPasangan.dataPernikahan.aktaCerai">
            Cerai<br />
            {{ item.kelPasangan.dataPernikahan.aktaCerai }} <br />
            {{ item.kelPasangan.dataPernikahan.tgglCerai }}
          </div>
        </template>
        <template
          v-slot:[`item.actions`]="{ item }"
          v-if="
            session.authorities.some((item) => item === 'CRUD_ADMINISTRATOR')
          "
        >
          <v-icon small class="mr-1" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import Auth from "@/helper/auth.js";

import rwpasanganModalEdit from "@/components/asn/profil/rwpasangan/ModalEdit";
import rwpasanganModalHapus from "@/components/asn/profil/rwpasangan/ModalHapus";

import modal_editState from "@/store/asn/rwpasangan/modal_edit";
import modal_hapusState from "@/store/asn/rwpasangan/modal_hapus";
import refreshView from "@/store/asn/rwpasangan/viewRwpasangan";

export default {
  name: "rwpasangan",

  components: {
    rwpasanganModalEdit,
    rwpasanganModalHapus,
  },

  computed: {
    refresh: {
      get() {
        return refreshView.state.Refresh;
      },
    },

    alertMassage: {
      get() {
        return refreshView.state.alertMassage;
      },
    },

    success: {
      get() {
        return refreshView.state.success;
      },

      set(value) {
        refreshView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return refreshView.state.alertBerhasil;
      },

      set(value) {
        refreshView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return refreshView.state.alertGagal;
      },

      set(value) {
        refreshView.commit("gagalAlert", value);
      },
    },

    headers() {
      const headers = [
        {
          text: "Nama Istri / Tempat Tanggal lahir",
          align: "start",
          sortable: false,
          value: "kolom1",
        },
        { text: "Akta Nikah / Tanggal Nikah", value: "kolom2" },
        { text: "Keterangan", value: "kolom3" },
      ];

      if (
        this.session.authorities.some((item) => item === "CRUD_ADMINISTRATOR")
      ) {
        headers.push({
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
        });
      }

      return headers;
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    rwpasangans: [],
    get_nip: "",
    session: "",

    ldata: true,

    //Kebutuhan Edit
    editedIndex: -1,
    hapusIndex: -1,

    // headers: [
    //   {
    //     text: "Nama Istri / Tempat Tanggal lahir",
    //     align: "start",
    //     sortable: false,
    //     value: "kolom1",
    //   },
    //   { text: "Akta Nikah / Tanggal Nikah", value: "kolom2" },
    //   { text: "Keterangan", value: "kolom3" },
    //   {
    //     text: "Actions",
    //     value: "actions",
    //     sortable: false,
    //     align: "center",
    //     width: "80px",
    //   },
    // ],
  }),

  async mounted() {
    this.session = JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.get_nip = this.$route.params.id;
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_ASN + "pasangan/nip/" + this.get_nip;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.ldata = false;
          refreshView.commit("refreshData", false);
          this.rwpasangans = response.data.mapData.DataKeluarga;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.rwpasangans.indexOf(item);
      this.rwpasangan = Object.assign({}, item);
      modal_editState.commit("toggleModal", true);
      modal_editState.commit("editModal", Object.assign({}, item));
    },

    deleteItem(item) {
      this.hapusIndex = this.rwpasangans.indexOf(item);
      this.rwpasangan = Object.assign({}, item);
      modal_hapusState.commit("toggleModal", true);
      modal_hapusState.commit("hapusModal", Object.assign({}, item));
    },
  },
};
</script>
