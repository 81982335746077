<template>
  <v-dialog
    v-model="ModalEdit"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="subtitle-1"
          >Edit Data Pasangan</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="form">
        <div class="ml-5 mr-5 mt-5">
          <!-- Nama Pasangan -->
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Nama Pasangan</span>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="editedItem.kelPasangan.nama"
                  hint="Nama Pasangan"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="editedItem.kelPasangan.NIK"
                  hint="NIK Pasangan"
                  persistent-hint
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-select
                  v-model="editedItem.kelPasangan.jenisKelamin"
                  :items="jenkel"
                  hint="Jenis Kelamin"
                  persistent-hint
                  dense
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-col>

          <!-- Tempat / Tanggal Lahir -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Tempat / Tanggal Lahir</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="editedItem.kelPasangan.tempatLahir"
                  hint="Tempat Lahir"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglLahirPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.kelPasangan.tglLahir"
                      hint="Tanggal Lahir"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.kelPasangan.tglLahir"
                    @input="tglLahirPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Data Kematian -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Data Kematian</span>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="editedItem.kelPasangan.aktaMeninggal"
                  hint="Akta Meninggal"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="tglMeninggalPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.kelPasangan.tglMeninggal"
                      hint="Tanggal Meninggal"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.kelPasangan.tglMeninggal"
                    @input="tglMeninggalPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Data Pernikahan -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Data Pernikahan</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="editedItem.kelPasangan.dataPernikahan.aktaMenikah"
                  hint="Akta Menikah"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-menu
                  v-model="tgglMenikahPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="
                        editedItem.kelPasangan.dataPernikahan.tgglMenikah
                      "
                      hint="Tanggal Menikah"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.kelPasangan.dataPernikahan.tgglMenikah"
                    @input="tgglMenikahPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <!-- Data Perceraian -->
          <v-col cols="12" class="mt-n5">
            <v-row>
              <v-col cols="12" md="2" class="mt-3">
                <span>Data Perceraian</span>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="editedItem.kelPasangan.dataPernikahan.aktaCerai"
                  hint="Akta Cerai"
                  persistent-hint
                  dense
                  flat
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-menu
                  v-model="tgglCeraiPicker"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="editedItem.kelPasangan.dataPernikahan.tgglCerai"
                      hint="Tanggal Cerai"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      dense
                      flat
                      outlined
                      readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="editedItem.kelPasangan.dataPernikahan.tgglCerai"
                    @input="tgglCeraiPicker = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>

          <hr />
          <div class="text-right mr-5 mt-5 pb-5">
            <v-btn v-if="btnLoading" color="primary" depressed @click="edit()"
              >SIMPAN</v-btn
            >
            <v-btn v-else color="primary" depressed loading>SIMPAN</v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from '@/helper/cookie.js'
import apiURL from '@/helper/getURL.js'
import Auth from '@/helper/auth.js'

import refreshView from '@/store/asn/rwpasangan/viewRwpasangan'
import modal_editState from '@/store/asn/rwpasangan/modal_edit'

export default {
  name: 'rwpasanganModalEdit',

  computed: {
    ModalEdit: {
      get() {
        return modal_editState.state.ModalEdit
      },

      set(value) {
        modal_editState.commit('toggleModal', value)
      },
    },

    editedItem: {
      get() {
        return modal_editState.state.rwpasangan
      },

      set(value) {
        console.log(value)
      },
    },
  },

  data: () => ({
    token: Cookie.get('token'),
    btnLoading: true,
    rwpasangan: {},

    jenkel: ['Pria', 'Wanita'],

    // Date Picker
    tglLahirPicker: false,
    tglMeninggalPicker: false,
    tgglMenikahPicker: false,
    tgglCeraiPicker: false,
  }),

  async mounted() {
    this.get_nip = this.$route.params.id
  },

  methods: {
    reset() {
      this.rwpasangan = {
        kelPasangan: {
          id: '',
          sapk: {
            id: null,
          },
          NIK: '',
          nama: '',
          tempatLahir: '',
          tglLahir: '',
          aktaMeninggal: '',
          tglMeninggal: null,
          jenisKelamin: null,
          dataPernikahan: {
            sapk: {
              id: null,
            },
            aktaMenikah: '',
            tgglMenikah: '',
            aktaCerai: '',
            tgglCerai: null,
          },
        },
      }
    },
    async edit() {
      this.btnLoading = false
      let token = await Cookie.get('token')
      let data = {
        id: this.editedItem.kelPasangan.id,
        NIK: this.editedItem.kelPasangan.NIK,
        nama: this.editedItem.kelPasangan.nama,
        tempatLahir: this.editedItem.kelPasangan.tempatLahir,
        tglLahir: this.editedItem.kelPasangan.tglLahir,
        aktaMeninggal: this.editedItem.kelPasangan.aktaMeninggal,
        tglMeninggal: this.editedItem.kelPasangan.tglMeninggal,
        jenisKelamin: this.editedItem.kelPasangan.jenisKelamin,
        dataPernikahan: {
          aktaMenikah: this.editedItem.kelPasangan.dataPernikahan.aktaMenikah,
          tgglMenikah: this.editedItem.kelPasangan.dataPernikahan.tgglMenikah,
          aktaCerai: this.editedItem.kelPasangan.dataPernikahan.aktaCerai,
          tgglCerai: this.editedItem.kelPasangan.dataPernikahan.tgglCerai,
        },
      }
      const url = apiURL.baseURL + 'pasangan/nip/' + this.get_nip
      this.http
        .put(url, data, { headers: apiURL.Update(token).headers })
        .then((response) => {
          this.btnLoading = true
          if (response.data.success) {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', response.data.message)
            refreshView.commit('berhasilAlert', true)
            refreshView.commit('gagalAlert', false)
            refreshView.commit('success', response.data.success)
          } else {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', response.data.message)
            refreshView.commit('gagalAlert', true)
            refreshView.commit('berhasilAlert', false)
            refreshView.commit('success', response.data.success)
          }
          this.closeModal()
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token)
          } else if (error.response) {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', error.response.data.message)
            refreshView.commit('gagalAlert', true)
            refreshView.commit('berhasilAlert', false)
            refreshView.commit('success', error.response.data.success)
            console.log(error.response.status)
            this.btnLoading = true
          } else {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', error.response.data.message)
            refreshView.commit('gagalAlert', true)
            refreshView.commit('berhasilAlert', false)
            refreshView.commit('success', error.response.data.success)
            console.log(error.response.status)
            console.log('Error', error.message)
            this.btnLoading = true
          }
        })
    },

    async closeModal() {
      await this.reset()
      await modal_editState.commit(
        'editModal',
        Object.assign({}, this.rwpasangan)
      )
      await refreshView.commit('refreshData', true)
      this.ModalEdit = false
    },
  },
}
</script>
