import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        ModalToSIMASN: false,
        dataUtama: {
            id: '',
            SAPK: {
                id: ''
            },
            nipBaru: '',
            nipLama: ''
        },
        rwpasangan: {
            kelPasangan: {
                id: '',
                sapk: {
                    id: null
                },
                NIK: '',
                nama: '',
                tempatLahir: '',
                tglLahir: '',
                aktaMeninggal: '',
                tglMeninggal: null,
                jenisKelamin: null,
                dataPernikahan: {
                    sapk: {
                        id: null
                    },
                    aktaMenikah: '',
                    tgglMenikah: '',
                    aktaCerai: '',
                    tgglCerai: null
                }
            }
        }
    },

    mutations: {
        toggleModal(state, value) {
            state.ModalToSIMASN = value
        },
        tosimasnModal(state, value) {
            state.rwpasangan = value
        },
        Datautama(state, value) {
            state.dataUtama = value
        }
    }
})
