<template>
  <v-dialog v-model="ModalToSIMASN" persistent max-width="40%">
    <v-card>
      <v-card-title class="headline"> Sinkron Data Ke SIMASN? </v-card-title>
      <v-card-text>
        Data pasangan yang anda pilih akan diselaraskan dengan data pasangan di
        SIMASN!</v-card-text
      >
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" small depressed @click="ModalToSIMASN = false">
          Batal
        </v-btn>
        <v-btn
          v-if="btnLoading"
          color="green"
          small
          depressed
          dark
          @click="toSAPK()"
        >
          Ya
        </v-btn>
        <v-btn v-else color="green" small depressed dark loading> Ya </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Cookie from '@/helper/cookie.js'
import apiURL from '@/helper/getURL.js'
import Auth from '@/helper/auth.js'

import refreshView from '@/store/asn/rwpasangan/viewRwpasangan'
import modal_tosimasnState from '@/store/asn/rwpasangan/modal_to_simasn'

export default {
  name: 'rwpasanganModalToSIMASN',

  data() {
    return {
      token: Cookie.get('token'),
      btnLoading: true,
      get_nip: '',
    }
  },

  computed: {
    ModalToSIMASN: {
      get() {
        return modal_tosimasnState.state.ModalToSIMASN
      },

      set(value) {
        modal_tosimasnState.commit('toggleModal', value)
      },
    },

    tosimasnItem: {
      get() {
        return modal_tosimasnState.state.rwpasangan
      },
    },

    editedDatautama: {
      get() {
        return modal_tosimasnState.state.dataUtama
      },
    },
  },

  methods: {
    async toSAPK() {
      this.btnLoading = false
      let token = await Cookie.get('token')
      this.get_nip = this.$route.params.id
      let data = {
        id: this.tosimasnItem.kelPasangan.id,
        NIK: this.tosimasnItem.kelPasangan.NIK,
        nama: this.tosimasnItem.kelPasangan.nama,
        tempatLahir: this.tosimasnItem.kelPasangan.tempatLahir,
        tglLahir: this.tosimasnItem.kelPasangan.tglLahir,
        aktaMeninggal: this.tosimasnItem.kelPasangan.aktaMeninggal,
        tglMeninggal: this.tosimasnItem.kelPasangan.tglMeninggal,
        jenisKelamin: this.tosimasnItem.kelPasangan.jenisKelamin,
        dataPernikahan: {
          aktaMenikah:
            this.tosimasnItem.kelPasangan.dataPernikahan.aktaMeninggal,
          tgglMenikah: this.tosimasnItem.kelPasangan.dataPernikahan.tgglMenikah,
          aktaCerai: this.tosimasnItem.kelPasangan.dataPernikahan.aktaCerai,
          tgglCerai: this.tosimasnItem.kelPasangan.dataPernikahan.tgglCerai,
        },
      }
      const url = apiURL.baseURL + 'pasangan/nip/' + this.get_nip
      this.http
        .post(url, data, { headers: apiURL.Update(token).headers })
        .then((response) => {
          this.btnLoading = true
          if (response.data.success) {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', response.data.message)
            refreshView.commit('berhasilAlert', true)
            refreshView.commit('gagalAlert', false)
            refreshView.commit('success', response.data.success)
          } else {
            refreshView.commit('refreshData', true)
            refreshView.commit('alert', response.data.message)
            refreshView.commit('gagalAlert', true)
            refreshView.commit('berhasilAlert', false)
            refreshView.commit('success', response.data.success)
          }
          this.ModalToSIMASN = false
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token)
          } else if (error.response) {
            this.btnLoading = true
            console.log(error.response.status)
          } else {
            this.btnLoading = true
            console.log('Error', error.message)
          }
        })
    },
  },
}
</script>
