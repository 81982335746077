<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="rwpasangan_SAPK"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      hide-default-footer
      :loading="loading"
    >
      <template v-slot:top>
        <v-alert v-model="alertBerhasil" type="success" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <v-alert v-model="alertGagal" type="error" dense dismissible>
          {{ alertMassage }}
        </v-alert>
        <rwpasanganModalToSIMASN />
      </template>
      <template v-slot:[`item.kolom1`]="{ item }">
        {{ item.kelPasangan.nama }} <br />
        {{ item.kelPasangan.tempatLahir }}, {{ item.kelPasangan.tglLahir }}
      </template>
      <template v-slot:[`item.kolom2`]="{ item }">
        {{ item.kelPasangan.dataPernikahan.aktaMenikah }} <br />
        {{ item.kelPasangan.dataPernikahan.tgglMenikah }}
      </template>
      <template v-slot:[`item.kolom3`]="{ item }">
        <div v-if="item.kelPasangan.dataPernikahan.aktaCerai">
          Cerai<br />
          {{ item.kelPasangan.dataPernikahan.aktaCerai }} <br />
          {{ item.kelPasangan.dataPernikahan.tgglCerai }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="toSIMASN(item)"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="primary"> mdi-database-arrow-left </v-icon>
            </v-btn>
          </template>
          <span>Salin Data</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import apiURL from '@/helper/getURL.js'
import Cookie from '@/helper/cookie.js'
import Auth from '@/helper/auth.js'

import rwpasanganModalToSIMASN from '@/components/asn/profil/rwpasangan/ModalToSIMASN'

import refreshView from '@/store/asn/rwpasangan/tabs'
import refreshData from '@/store/asn/rwpasangan/viewRwpasangan'
// import modal_tosimasnState from '@/store/asn/rwpasangan/modal_to_simasn'

export default {
  name: 'rwpasanganModalSAPK',

  components: {
    rwpasanganModalToSIMASN,
  },

  computed: {
    tabSAPK: {
      get() {
        return refreshView.state.tabSAPK
      },
    },

    refresh: {
      get() {
        return refreshData.state.RefreshSAPK
      },
    },

    alertMassage: {
      get() {
        return refreshData.state.alertMassage
      },
    },

    success: {
      get() {
        return refreshData.state.success
      },

      set(value) {
        refreshData.commit('alert', value)
      },
    },

    alertBerhasil: {
      get() {
        return refreshData.state.alertBerhasil
      },

      set(value) {
        refreshData.commit('berhasilAlert', value)
      },
    },

    alertGagal: {
      get() {
        return refreshData.state.alertGagal
      },

      set(value) {
        refreshData.commit('gagalAlert', value)
      },
    },
  },

  watch: {
    tabSAPK(newVal) {
      if (newVal) {
        this.getDataSAPK()
      }
    },
  },

  data: () => ({
    token: Cookie.get('token'),
    Modal_rwpasangan_SAPK: false,
    loading: false,
    rwpasangan_SAPK: [],
    get_nip: '',

    headers: [
      {
        text: 'Nama Istri / Tempat Tanggal lahir',
        align: 'start',
        sortable: false,
        value: 'kolom1',
      },
      { text: 'Akta Nikah / Tanggal Nikah', value: 'kolom2' },
      { text: 'Keterangan', value: 'kolom3' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
    ],
  }),

  async mounted() {
    this.get_nip = this.$route.params.id
    if (this.tabSAPK) {
      this.getDataSAPK()
    }
  },

  methods: {
    getDataSAPK() {
      this.loading = true
      const token = Cookie.get('token')
      const url = apiURL.baseURL + 'pasangan/nip/' + this.get_nip + '/bkn'

      this.http
        .get(
          url,
          { headers: apiURL.Config(token).headers },
          apiURL.Config(token).data
        )
        .then((response) => {
          this.rwpasangan_SAPK = response.data.mapData.DataKeluarga
          this.loading = false
        })
        .catch((error) => {
          if (error.response.status == 401) {
            Auth.logout(this.token)
          } else if (error.response) {
            console.log(error.response.status)
          } else {
            console.log('Error', error.message)
          }
        })
    },

    toSIMASN(item) {
      // modal_tosimasnState.commit('toggleModal', true)
      // modal_tosimasnState.commit('tosimasnModal', Object.assign({}, item))
      // modal_tosimasnState.commit('Datautama', Object.assign({}, this.dataUtama))
      console.log(item);
    },
  },
}
</script>
