import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalEdit: false,
    dataUtama: {
      id: '',
      sapk: {
        id: ''
      },
      nipBaru: '',
      nipLama: ''
    },
    rwpasangan: {
      kelPasangan: {
        id: '',
        sapk: {
          id: null
        },
        NIK: '',
        nama: '',
        tempatLahir: '',
        tglLahir: '',
        aktaMeninggal: '',
        tglMeninggal: null,
        jenisKelamin: null,
        dataPernikahan: {
          sapk: {
            id: null
          },
          aktaMenikah: '',
          tgglMenikah: '',
          aktaCerai: '',
          tgglCerai: null
        }
      }
    }
  },

  mutations: {
    toggleModal(state, value) {
      state.ModalEdit = value
    },
    editModal(state, value) {
      state.rwpasangan = value
    },
    editDatautama(state, value) {
      state.dataUtama = value
    }
  }
})
